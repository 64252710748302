import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  console.log('to', to, to.meta, to.meta.needLogin)

  if (to.meta.needLogin) {
    const token = window.localStorage.getItem('token')
    console.log('token', token)
    if (!token) {
      store.commit('login/setVisible', true)
      return false;
    } else {
      next()
      // return true;
    }
  } else {
    next();
    // return true;
  }

})



export default router

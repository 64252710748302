import Vue from 'vue'

function date(val, fmt) {
    val = val || Date.parse(new Date()) / 1000;
    fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
    var d = new Date()
    d.setTime(val * 1000)

    var o = {
        "M+": d.getMonth() + 1,                 //月份 
        "d+": d.getDate(),                    //日 
        "h+": d.getHours(),                   //小时 
        "m+": d.getMinutes(),                 //分 
        "s+": d.getSeconds(),                 //秒 
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度 
        "S": d.getMilliseconds()             //毫秒 
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
        }
    }
    return fmt
}


Vue.filter('byteTrans', bytes => {
    console.log(bytes, 'bytes')
    if (bytes < 1024) return '1Kb'
    else if (bytes > 1024 && bytes < 1024 * 1024) {
        bytes = bytes / 1024
        return bytes.toFixed(1) + 'Kb'
    } else {
        bytes = bytes / 1024 / 1024
        return bytes.toFixed(1) + 'M'
    }
})

// vue 全局过滤器: 时间转换，如果不指定fmt，则友好显示时间
// {{ create_time | timeTrans('yyyy-MM-dd hh:mm:ss') }}
Vue.filter('timeTrans', (value, fmt) => {
    value = parseInt(value)

    if (value == 0 || !value) return '-'

    if (fmt) {
        return date(value, fmt)
    } else {
        var d, d_minutes, d_hours, d_days
        var timeNow = parseInt(new Date().getTime() / 1000)
        d = timeNow - value;
        d_days = parseInt(d / 86400)
        d_hours = parseInt(d / 3600)
        d_minutes = parseInt(d / 60)

        if (d_days > 0 && d_days < 4) {
            return d_days + "天前";
        } else if (d_days <= 0 && d_hours > 0) {
            return d_hours + "小时前";
        } else if (d_hours <= 0 && d_minutes > 0) {
            return d_minutes + "分钟前";
        } else if (d < 60) {
            return "刚刚";
        } else {
            var s = new Date(value * 1000);
            return s.getFullYear() + "/" + (s.getMonth() + 1) + "/" + s.getDate()
        }
    }
})

// vue 全局过滤器: 长字符串切断
Vue.filter('strCut', function (str, maxlength) {
    return str.length > maxlength ? str.substr(0, maxlength) + '...' : str
})

// vue 全局过滤器: html编码转换
Vue.filter('htmlFilter', function (str) {
    if (str) {
        var arr = {
            'lt': '<',
            'gt': '>',
            'nbsp': ' ',
            'amp': '&',
            'quot': '"'
        }

        return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
            return arr[t]
        })
    }
})

// vue 全局过滤器: 隐藏用户名
Vue.filter('nameHide', name => {
    return name ? name[0] + '****' : ''
})

// vue 全局过滤器: 隐藏用户名
Vue.filter('timeReplace', t => {
    return t.replace('T', ' ').replace('.000Z', '')
})

//////////////////////////////////////////////////////////////
// 业务相关
//////////////////////////////////////////////////////////////
Vue.filter('moneyFormat', num => {
    if (num) {
        num = parseFloat(num);
        num = String(num.toFixed(2));
        var re = /(-?\d+)(\d{3})/;
        while (re.test(num)) {
            num = num.replace(re, "$1,$2");
        }
        return num
    } else {
        return "0"
    }
})

Vue.filter('orderStatusFormat', v => {
    switch (v) {
        case 'CREATED': return '待取件'; case 'FETCHED': return '待审核'; case 'CHECKING': return '审核中'; case 'SENT': return '已寄件'; case 'RECEIVED': return '已签收'; case 'RETURNED': return '已退回'; case 'EXPIRED': return '已过期'; default: return '未知状态'
    }
})

Vue.filter('sexFormat', v => {
    return v == 0 ? '男' : '女'
})

import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import major from './modules/major'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    major,
  }
})

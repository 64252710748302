export default [
  {
    path: '/sign-up',
    component: () => import('@/views/sign-up/index.vue')
  },
  {
    path: '/sign-up/data',
    component: () => import('@/views/sign-up/data.vue')
  },
  {
    path: '/sign-up/admin',
    component: () => import('@/views/sign-up/admin.vue')
  },

  {
    path: '/resume',
    component: () => import('@/views/resume')
  },
  {
    path: '/',
    component: () => import('@/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/index'),
      },
      {
        path: 'search',
        component: () => import('@/views/search'),
      },
      {
        path: "company-detail",
        component: () => import('@/views/company-detail'),
        meta: {
          needLogin: true,
        }
      },
      {
        path: "job-detail",
        component: () => import('@/views/job-detail'),
        meta: {
          needLogin: true,
        }
      },
      {
        path: 'news',
        component: () => import('@/views/news'),
      },
      {
        path: 'news/detail',
        component: () => import('@/views/news/detail'),
      },
      {
        path: 'video',
        component: () => import('@/views/video'),
      },
      {
        path: 'video/detail',
        component: () => import('@/views/video/detail'),
      },

      {
        path: 'activity',
        component: () => import('@/views/activity'),
      },
      {
        path: 'activity/detail',
        component: () => import('@/views/activity/detail'),
      },
      {
        path: 'me',
        component: () => import('@/views/me'),
        meta: {
          needLogin: true,
        }
      },
      {
        path: '*',
        component: () => import('@/views/404'),

      }
    ]
  },
]
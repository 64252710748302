import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Loading from '@/components/loading'

import './css/index.less'

import './utils/lang'
import './utils/api'


Vue.component('Loading', Loading)

Vue.prototype.L = window.L;
Vue.prototype.L2 = window.L2
Vue.prototype.G = {};

Vue.prototype.toFixed = function (num, n) {
  if (isNaN(num)) {
    return false;
  }

  let p = Math.pow(10, n);
  return Math.round(num * p) / p;
}

import {
  Button, Icon, Card, Table, Tabs, Form,
  Row, Col, Alert, Input, Checkbox, Select, Modal,
  Space, Divider, Dropdown, Badge, Avatar, Tree, message,
  Result, Upload, Progress, Breadcrumb, Statistic, List,
  Steps, Radio, InputNumber, Tooltip, ConfigProvider,
  Empty, Pagination, DatePicker, Tag, Popconfirm,
  Carousel, Cascader, Skeleton, Slider, Spin,
  Menu,Switch
} from 'ant-design-vue'


import { imgCrop } from '@/utils/image'

Vue.config.productionTip = false

Vue
  .use(Button).use(Icon).use(Card).use(Table)
  .use(Tabs).use(Form).use(Row).use(Col).use(Alert).use(Input).use(Checkbox)
  .use(Select).use(Modal).use(Space).use(Divider).use(Dropdown)
  .use(Badge).use(Avatar).use(Tree).use(Result).use(Upload).use(Progress)
  .use(Breadcrumb).use(Statistic).use(List).use(Steps).use(Radio)
  .use(InputNumber).use(Tooltip).use(ConfigProvider)
  .use(Empty).use(Pagination).use(DatePicker).use(Tag)
  .use(Carousel).use(Cascader).use(Skeleton).use(Slider)
  .use(Spin).use(Popconfirm).use(Menu).use(Switch)

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$error = Modal.error;

Vue.prototype.$imgCrop = imgCrop;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

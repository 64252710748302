<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>


<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      zh_CN: Object.freeze(zh_CN),
    };
  },

  methods: {
    ...mapMutations("login", ["setResume"]),
    ...mapMutations("major", ["setSelected"]),
  },

  computed: {},

  mounted() {
    // 1. 恢复用户基本信息
    const resume = window.localStorage.getItem("resume");
    if (resume) {
      this.setResume(JSON.parse(resume));
    }
    // 恢复用户选择的专业
    const selectedMajor = window.localStorage.getItem("selectedMajor");
    if (selectedMajor) {
      this.setSelected(JSON.parse(selectedMajor));
    }
  },
};
</script>
export default {

    namespaced: true,

    state() {
        return {
            visible: false, // 登录注册弹窗显示隐藏
            resume: {}, // 简历信息
        }
    },

    mutations: {
        setVisible(state, payload) {
            state.visible = payload
        },
        setResume(state, payload) {
            state.resume = payload
        }
    },

}

export default {

    namespaced: true,

    state() {
        return {
            visible: false, // 选择专业弹窗显示隐藏
            selected: [], // 已选中的专业
        }
    },

    mutations: {
        setVisible(state, payload) {
            state.visible = payload
        },
        setSelected(state, payload) {
            state.selected = payload
        }
    },

}
